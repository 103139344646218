import React from 'react';

import Layout from '../components/Layout';

const herobg = '#1c2841', herofcolor='#efefef';
const TransformPage = () => (
  <Layout>
    <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                      <h1 style={{letterSpacing:'.1em'}}>Cloutics Privacy Policy</h1>
              </header>
      </div>
    
      <div className="inner" style={{margin:'.25em auto'}}>
          
        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
            Overview
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
            Thank you for visiting Cloutics.com/Cloutics.net ("Website") and reviewing our Privacy Policy. 
            Your privacy is important to us, and our policy is simple: we will collect no personally identifiable 
            information about you when you visit the Web Site unless you choose to provide that information. 
            This Privacy Policy does not describe information collection practices on other sites, including those 
            linked to or from the Web Site.
        </p>
        
        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
            What Type of Information We Collect
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
            The server on which the Web Site is located collects and saves only the default information customarily 
            logged by web server software. Such information may include the date and time of your visit, 
            the originating IP address, and the pages and images requested.
        </p>

        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
            Children's Issues
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
            The Web Site is not directed to children under thirteen (13) years of age, and children under such 
            age must not use the web site or services offered on it to submit any individually identifiable information 
            about themselves.
        </p>

        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
            Copyright
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
            The materials found on the Web Site are protected by United States and other copyright laws, 
            except for works of the United States Government pursuant to 1 U.S.C. Section 105. The selection, 
            arrangement and presentation of all materials (including information in the public domain), and the overall 
            design of the Website are copyright ©{(new Date()).getFullYear()}, Cloutics Inc. Permission is granted to view 
            and print materials from the Website for non-commercial purpose of viewing, reading and retaining for reference. 
            Any other copying, distribution, retransmission or modification of information or materials on this site, 
            whether in electronic or hard copy form, without the express prior written permission of Cloutics Inc, 
            is strictly prohibited.
        </p>

        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
            Trademarks
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
          Trademarks or service marks mentioned in this website belongs to the respective owners.
        </p>

        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
            No Warranties
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
             DOES NOT MAKE ANY EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER 
             (INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A 
             PARTICULAR PURPOSE) WITH REGARD TO THE WEBSITE, THE MATERIALS, ANY PRODUCTS, INFORMATION OR SERVICE 
             PROVIDED THROUGH THE WEBSITE, OR ANY SITES LISTED THEREIN, AND THE WEBSITE WILL NOT BE LIABLE FOR ANY 
             COST OR DAMAGE ARISING EITHER DIRECTLY OR INDIRECTLY FROM ANY SUCH TRANSACTION. THE WEBSITE DOES NOT 
             WARRANT THAT IT WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN IT WILL BE CORRECTED. 
             THE WEBSITE IS PROVIDED ON AN "AS IS, AS AVAILABLE" BASIS. IN NO EVENT WILL THE WEBSITE OR ITS MEMBERS, 
             MANAGERS, OFFICERS, EMPLOYEES, AGENTS AND THE ASSIGNS OF SAME BE LIABLE FOR 
             <br/>(1) ANY INCIDENTAL, CONSEQUENTIAL OR INDIRECT DAMAGES (INCLUDING, BUT NOT LIMITED TO, 
             DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR INFORMATION, AND THE LIKE) 
             ARISING OUT OF THE USE OF OR INABILITY TO USE THE WEBSITE, THE MATERIALS OR ANY INFORMATION, 
             OR TRANSACTIONS PROVIDED ON THE WEBSITE OR DOWNLOADED FROM THE WEB SITE, EVEN IF THE WEBSITE OR ITS 
             AUTHORIZED REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR 
             <br />(2) ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS OR OTHER INACCURACIES IN THE WEBSITE, THE MATERIALS 
             AND/OR LISTINGS OR INFORMATION DOWNLOADED THROUGH THE WEBSITE. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION 
             OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. 
             IN SUCH STATES, THE WEBSITE'S LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
        </p>
        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
            Indemnification
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
            You agree to indemnify, defend and hold harmless the Website and its members, managers, officers, 
            employees, agents and the assigns of same, from and against any and all loss, costs, expenses 
            (including reasonable attorneys' fees and expenses), claims, damages and liabilities related 
            to or associated with your use of the Website and any violation of this Privacy Policy by you.
        </p>
        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
            Governing Law and Jurisdiction
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
            This Privacy Policy constitutes a contract made under and shall be governed by and construed in accordance 
            with the laws of the State of New Jersey, USA.
        </p>
        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
            Last Update
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
            This Privacy Policy was last updated on Oct 1st 2020.
        </p>
        <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
           Contact Information
        </p>
        <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
            Questions or comments regarding the Website must be sent by the link "Reach Us" 
            provided on the top header of the page
            or by U.S. mail to the US (NJ) address mentioned in the "About Us" page.
        </p>

          
        
        
                
        
        </div>
      </div>
    
  </Layout>
);

export default TransformPage;
